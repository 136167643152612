import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['section']
  static values = { interval: Number }

  connect () {
    this.currentSectionIndex = 0
    this.showSection(this.currentSectionIndex)
    this.startCycling()
  }

  startCycling () {
    this.interval = setInterval(() => {
      this.currentSectionIndex++

      if (this.currentSectionIndex < this.sectionTargets.length) {
        this.hideAllSections()
        this.showSection(this.currentSectionIndex)
      } else {
        clearInterval(this.interval)
      }
    }, this.intervalValue)
  }

  showSection (index) {
    this.sectionTargets[index].classList.remove('hidden')
  }

  hideAllSections () {
    this.sectionTargets.forEach((section) => {
      section.classList.add('hidden')
    })
  }

  disconnect () {
    clearInterval(this.interval)
  }
}
