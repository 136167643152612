import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'
import Masonry from 'masonry-layout'

export default class extends Controller {
  static values = {
    itemSelector: String,
    columnWidth: Number,
    gutter: Number,
    enableMutate: Boolean,
    loadTimeout: Number,
    fitWidth: Boolean
  }

  get enableMutate () {
    return this.enableMutateValue || false
  }

  get loadTimeout () {
    return this.loadTimeoutValue || 0
  }

  get itemSelector () {
    return this.itemSelectorValue || '.grid-component'
  }

  get columnWidth () {
    return this.columnWidthValue || '.grid-sizer'
  }

  get gutter () {
    return this.gutterValue || '.grid-sizer'
  }

  connect () {
    this.masonry = new Masonry(this.element, {
      itemSelector: this.itemSelector,
      columnWidth: this.columnWidth,
      gutter: this.gutter,
      percentPosition: true,
      fitWidth: this.fitWidthValue,
      horizontalOrder: true,
      transitionDuration: 0
    })

    this.masonry.on('layoutComplete', this.onLayout.bind(this))
    this.masonry.layout()

    if (this.enableMutate) {
      useMutation(this, {
        attributes: true,
        childList: true,
        subtree: true
      })
    }
  }

  onLayout () {
    setTimeout(() => {
      this.element.classList.add('--loaded')
    }, this.loadTimeout)
  }

  reload () {
    this.masonry.layout()
  }

  mutate (entries) {
    for (const mutation of entries) {
      if (mutation.type === 'attributes') {
        this.masonry.layout()
      }
    }
  }
}
