import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['checkable', 'submitButton']

  displaySubmitButton () {
    if (this.checkedTargets.length > 0) {
      this.submitButtonTarget.classList.remove('hidden')
    } else {
      this.submitButtonTarget.classList.add('hidden')
    }
  }

  get checkedTargets () {
    return this.checkableTargets.filter(target => target.checked)
  }

  submit (e) {
    this.form = e.target.parentElement

    this.checkedTargets.forEach(target => {
      const clone = target.cloneNode(true)
      clone.classList.add('hidden')
      this.form.appendChild(clone)
    })

    Turbo.navigator.submitForm(this.form)

    this.checkedTargets.forEach(target => {
      target.checked = false
    })

    this.submitButtonTarget.classList.add('hidden')
  }
}
