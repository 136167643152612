import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    message: String
  }

  connect () {
    window.dispatchEvent(new Event(this.messageValue, { bubbles: true }))
  }
}
