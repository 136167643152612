/* global MutationObserver */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['loadingBody', 'asyncElementsContainer']

  connect () {
    this.observer = new MutationObserver(this.checkCompletedStatus.bind(this))

    this.observer.observe(this.asyncElementsContainerTarget, { attributes: true, attributeFilter: ['complete'], childList: true, subtree: true })
  }

  disconnect () {
    this.observer.disconnect()
  }

  checkCompletedStatus () {
    if (this.allElementsCompleted) {
      this.completeRender()
    }
  }

  get allElementsCompleted () {
    return Array.from(this.asyncElementsContainerTarget.children).every(component => component.hasAttribute('complete'))
  }

  completeRender () {
    this.loadingBodyTarget.classList.add('hidden')
    this.asyncElementsContainerTarget.classList.remove('hidden')
    this.observer.disconnect()
  }
}
