import Dropdown from '@stimulus-components/dropdown'
import { hasTurboPermanentParent, controllerPreviouslyConnected, setControllerConnectedDataAttribute } from '../utils/connection_status'

export default class extends Dropdown {
  connect () {
    if (!hasTurboPermanentParent(this.element) || !controllerPreviouslyConnected(this.element)) {
      super.connect()
    }

    if (hasTurboPermanentParent(this.element)) {
      setControllerConnectedDataAttribute(this.element)
    }
  }

  toggle () {
    super.toggle()

    if (this.element.classList.contains('is-open')) {
      this.element.classList.remove('is-open')
      this.element.style.zIndex = ''
    } else {
      this.element.classList.add('is-open')
      this.element.style.zIndex = '201'
    }
  }

  hide (event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.element.classList.remove('is-open')
      this.element.style.zIndex = ''
      this.leave()
    }
  }
}
