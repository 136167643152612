import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['checkbox', 'metadataField', 'form']
  static POSITION_TITLE_DIMENSION = 'profile.position.title'

  skip (event) {
    this.removeSuggestionsFromMetadata()
    this.submitForm(event)
  }

  submit (event) {
    this.removeUncheckedSuggestions()
    this.submitForm(event)
  }

  removeSuggestionsFromMetadata () {
    const suggestionToRemove = this.checkboxTargets.map(checkbox => checkbox.name)
    this.updateMetadata(suggestionToRemove)
  }

  removeUncheckedSuggestions () {
    const suggestionToRemove = this.checkboxTargets.filter(checkbox => !checkbox.checked).map(checkbox => checkbox.name)
    this.updateMetadata(suggestionToRemove)
  }

  submitForm (event) {
    event.preventDefault()
    Turbo.navigator.submitForm(this.formTarget)
  }

  updateMetadata (suggestionsToRemove) {
    const metadataValue = JSON.parse(this.metadataFieldTarget.value)
    this.updateConditions(metadataValue.query.targets, suggestionsToRemove)
    this.metadataFieldTarget.value = JSON.stringify(metadataValue)
  }

  updateConditions (targets, suggestionsToRemove) {
    targets.forEach(target => {
      target.conditions = target.conditions.filter(condition => {
        const conditionText = condition.text?.toString() || ''
        const conditionDimension = condition.dimension?.toString() || ''
        const removeResult = conditionDimension === this.constructor.POSITION_TITLE_DIMENSION && suggestionsToRemove.includes(conditionText)
        return !removeResult
      })
    })
  }
}
