import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    method: String,
    url: String,
    body: Object
  }

  connect () {
    this.makeRequest()
  }

  makeRequest () {
    switch (this.methodValue) {
      case 'post':
        this.postRequest()
        break
      case 'get':
        this.getRequest()
        break
      default:
        // noop
    }
  }

  getRequest () {
    get(this.urlValue)
  }

  postRequest () {
    post(
      this.urlValue,
      {
        body: this.bodyValue,
        contentType: 'application/json'
      }
    )
  }
}
