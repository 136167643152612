import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field', 'element']

  initialize () {
    this.setEnabled()
  }

  setEnabled () {
    if (this.fieldTargets.every(this.fieldHasValue.bind(this))) {
      this.enableElement()
    } else {
      this.disableElement()
    }
  }

  fieldHasValue (field) {
    return field.value.length > 0
  }

  enableElement () {
    this.elementTarget.disabled = false
  }

  disableElement () {
    this.elementTarget.disabled = true
  }
}
