import { Controller } from '@hotwired/stimulus'

import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    delay: Number
  }

  connect () {
    this.scheduleNextRequest()
  }

  async makeRequest () {
    const response = await get(this.urlValue)

    if (response.ok) {
      this.scheduleNextRequest()
    }
  }

  scheduleNextRequest () {
    setTimeout(() => {
      this.makeRequest()
    }, this.delayValue)
  }
}
