import { Controller } from '@hotwired/stimulus'

export default class ActionOnEventController extends Controller {
  static classes = ['action']
  static targets = ['element']

  click () {
    this.receivingElement.click()
  }

  addClass () {
    this.receivingElement.classList.add(this.actionClass)
  }

  removeElement () {
    this.receivingElement.remove()
  }

  removeClass () {
    this.receivingElement.classList.remove(this.actionClass)
  }

  closeModal () {
    document.body.style.removeProperty('overflow')
    this.modalContainer.innerHTML = ''
  }

  enable () {
    this.receivingElement.removeAttribute('disabled')
  }

  disable () {
    this.receivingElement.setAttribute('disabled', true)
  }

  submit () {
    this.receivingElement.dispatchEvent(new Event('submit', { bubbles: true }))
  }

  get modalContainer () {
    return document.getElementById('modal_container')
  }

  get receivingElement () {
    if (this.hasElementTarget) {
      return this.elementTarget
    } else {
      return this.element
    }
  }
}
