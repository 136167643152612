import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['text', 'button']
  static values = {
    maxHeight: Number,
    expandedText: String,
    collapsedText: String
  }

  connect () {
    this.expanded = false
    this.checkOverflow()
  }

  toggle () {
    this.expanded = !this.expanded
    this.toggleText()
  }

  checkOverflow () {
    this.textTarget.style.maxHeight = this.maxHeight
    if (this.textTarget.scrollHeight <= this.textTarget.clientHeight) {
      this.buttonTarget.classList.add('hidden')
    } else {
      this.textTarget.style.maxHeight = this.maxHeight
      this.buttonTarget.classList.remove('hidden')
    }
  }

  toggleText () {
    if (this.expanded) {
      this.textTarget.style.maxHeight = 'none'
      this.buttonTarget.textContent = this.expandedTextValue
    } else {
      this.textTarget.style.maxHeight = this.maxHeight
      this.buttonTarget.textContent = this.collapsedTextValue
    }
  }

  get maxHeight () {
    return `${this.maxHeightValue}px`
  }
}
