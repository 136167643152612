/* global MutationObserver */

import { Controller } from '@hotwired/stimulus'
import { isEqual } from 'lodash-es'

export default class extends Controller {
  static targets = ['form', 'button', 'sidebar', 'undoButton']
  static values = {
    requirementType: String
  }

  connect () {
    this.setInitialFormValues()
    this.observer = new MutationObserver(this.checkPristineStatus.bind(this))
    this.observer.observe(this.formTarget, { attributes: true, childList: true, subtree: true })
    window.addEventListener('resetTypeaheadFormState', this.resetFormState.bind(this))
  }

  disconnect () {
    this.observer.disconnect()
  }

  resetFormState () {
    this.setInitialFormValues()
    this.checkPristineStatus()
  }

  setInitialFormValues () {
    this.initialFormValues = this.formValues
  }

  checkPristineStatus () {
    if (this.formIsPristine) {
      this.setPristineState()
    } else {
      this.setDirtyState()
    }
  }

  get formIsPristine () {
    return isEqual(this.initialFormValues, this.formValues)
  }

  get formValues () {
    switch (this.requirementTypeValue) {
      case 'checkbox':
        return this.checkboxFormValues
      case 'pill':
        return this.pillFormValues
      case 'input':
        return this.formInputValues
      case 'radio':
        return this.formRadioValues
      case 'pillAndCheckbox':
        return this.pillAndCheckboxValues
    }
  }

  get pillAndCheckboxValues () {
    return {
      pills: this.pillFormValues,
      checkboxes: this.checkboxFormValues
    }
  }

  get checkboxFormValues () {
    const checkedCheckboxes = this.formTarget.querySelectorAll('input[type="checkbox"]:checked')
    return Array.from(checkedCheckboxes).map(checkbox => checkbox.id)
  }

  get formRadioValues () {
    const checkedRadioButtons = this.formTarget.querySelectorAll('input[type="radio"]:checked')
    return Array.from(checkedRadioButtons).map(radioButton => radioButton.id)
  }

  get pillFormValues () {
    const fields = this.formTarget.querySelectorAll('input[name="search_conditions[][text]"]')
    return Array.from(fields).map(field => field.value)
  }

  get formInputValues () {
    const fields = this.formTarget.querySelectorAll('input')
    return Array.from(fields)
      .filter(field => field.id)
      .map(field => ({ id: field.id, value: field.value }))
  }

  setPristineState () {
    this.disableButton()
    this.unlockSidebar()
    this.disableUndoButton()
  }

  setDirtyState () {
    this.enableButton()
    this.lockSidebar()
    this.enableUndoButton()
  }

  disableButton () {
    this.buttonTarget.setAttribute('disabled', true)
  }

  enableButton () {
    this.buttonTarget.removeAttribute('disabled')
  }

  enableUndoButton () {
    this.undoButtonTarget.removeAttribute('disabled')
  }

  disableUndoButton () {
    this.undoButtonTarget.setAttribute('disabled', true)
  }

  unlockSidebar () {
    this.sidebarTarget.classList.remove('sidebar-locked')
  }

  lockSidebar () {
    this.sidebarTarget.classList.add('sidebar-locked')
  }
}
