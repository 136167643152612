import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'itemsContainer',
    'item',
    'overflowItem',
    'overflowItemCount'
  ]

  static classes = ['hiddenItem']

  static values = {
    maxRowCount: Number,
    mobileMaxRowCount: Number,
    expanded: Boolean,
    showLess: String,
    overflowPrefix: String,
    overflowSuffix: String,
    hideOverflowCount: Boolean
  }

  initialize () {
    this.redrawInterval = setInterval(
      this.redrawItems.bind(this),
      20
    )
  }

  get itemRowLimit () {
    const windowWidth = window.innerWidth
    return windowWidth >= 1024
      ? this.maxRowCountValue
      : this.mobileMaxRowCountValue
  }

  redrawItems () {
    clearInterval(this.redrawInterval)

    if (this.itemRowLimit >= 0) {
      this.hideItemTargets()
      this.processItemsList()
    }
  }

  screenResize () {
    if (!this.expandedValue) this.redrawItems()
  }

  processItemsList () {
    let rowCount = 0
    let previousElementHeight = 0

    window.requestAnimationFrame(() => {
      if (this.hasOverflowItemTarget) this.hideOverflowItemPill()

      // eslint-disable-next-line array-callback-return, consistent-return
      this.itemTargets.some((itemTarget, idx) => {
        this.showItem(itemTarget)

        if (this.itemsContainerTarget.clientHeight !== previousElementHeight) {
          rowCount += 1
          previousElementHeight = this.itemsContainerTarget.clientHeight
        }

        if (rowCount > this.itemRowLimit) {
          this.hideItem(itemTarget)

          if (this.hasOverflowItemTarget) this.showOverflowItem(idx, this.itemsContainerTarget.clientHeight)
          return true
        }
      })
    })
  }

  hideItemTargets () {
    this.itemTargets.forEach(itemTarget => this.hideItem(itemTarget))
  }

  showItemTargets () {
    this.itemTargets.forEach(itemTarget => this.showItem(itemTarget))
  }

  hideItem (itemTarget) {
    itemTarget.classList.add(this.hiddenItemClass)
  }

  showItem (itemTarget) {
    itemTarget.classList.remove(this.hiddenItemClass)
  }

  toggleDisplayedItems () {
    if (this.expandedValue) {
      this.redrawItems()
    } else {
      this.showItemTargets()
      if (this.hasOverflowItemTarget) {
        this.showItem(this.overflowItemTarget)
        this.overflowItemCountTarget.textContent = this.showLessValue
      }
    }
    this.expandedValue = !this.expandedValue
  }

  hideOverflowItemPill () {
    this.hideItem(this.overflowItemTarget)
  }

  showOverflowItem (displayedItemsCount, previousElementHeight) {
    const hiddenItemCount = this.itemTargets.length - displayedItemsCount

    if (hiddenItemCount > 0) {
      const prefix = this.overflowPrefixValue || '+'
      const suffix = this.overflowSuffixValue || ''
      let countText = this.hideOverflowCountValue ? ' ' : ` ${hiddenItemCount} `

      this.overflowItemCountTarget.textContent = `${prefix}${countText}${suffix}`.trim()
      this.showItem(this.overflowItemTarget)

      if (this.itemsContainerTarget.clientHeight !== previousElementHeight) {
        const lastNamedItemIndex = displayedItemsCount - 1
        // Hide last two Items and re-add the item counter again
        this.hideItem(this.itemTargets[lastNamedItemIndex])
        countText = this.hideOverflowCountValue ? ' ' : ` ${hiddenItemCount + 1} `
        this.overflowItemCountTarget.textContent = `${prefix}${countText}${suffix}`.trim()
      }
    }
  }
}
