import { Controller } from '@hotwired/stimulus'
import { controllerPreviouslyConnected, setControllerConnectedDataAttribute } from '../utils/connection_status'

export default class extends Controller {
  static values = {
    delay: Number
  }

  initialize () {
    if (!controllerPreviouslyConnected(this.element)) {
      setTimeout(() => {
        setControllerConnectedDataAttribute(this.element)
      }, this.delayValue)
    }
  }
}
