import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    message: String
  }

  dispatchEvent (event) {
    window.dispatchEvent(new Event(event.params.message, { bubbles: true }))
  }
}
