import { Controller } from '@hotwired/stimulus'

export default class ToastInsertionController extends Controller {
  static targets = ['template', 'container']
  static classes = ['toastText']
  static values = {
    message: String
  }

  insertToast (e) {
    const { toastMessage } = e.currentTarget.dataset

    this.insertToastWithMessage(toastMessage)
  }

  insertToastWithMessage (toastMessage) {
    this.clearToasts()
    const toast = this.createToast(toastMessage)
    this.containerTarget.appendChild(toast)
  }

  clearToasts () {
    this.containerTarget.innerHTML = ''
  }

  createToast (message) {
    const template = this.templateTarget.cloneNode(true)
    const toastElement = document.importNode(template.content, true)
    const toastText = toastElement.querySelector(`.${this.toastTextClass}`)
    toastText.innerHTML = message
    return toastElement
  }
}
