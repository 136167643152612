import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggle (event) {
    event.preventDefault()
    const radio = event.target
    setTimeout(() => {
      radio.checked = !radio.checked
      const changeEvent = new Event('change', { bubbles: true })
      radio.dispatchEvent(changeEvent)
    }, 0)
  }
}
