export function controllerPreviouslyConnected (controllerElement) {
  return controllerElement.hasAttribute('data-controller-connected')
}

export function setControllerConnectedDataAttribute (controllerElement) {
  controllerElement.setAttribute('data-controller-connected', 'true')
}

export function hasTurboPermanentParent (controllerElement) {
  return !!controllerElement.closest('[data-turbo-permanent]')
}
