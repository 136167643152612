import { Controller } from '@hotwired/stimulus'
import { getClosestElementFor } from '../utils/dom'

export default class IframeRequestHandlerController extends Controller {
  connect () {
    window.addEventListener('message', this.handleMessage.bind(this), false)

    window.addEventListener('turbo:before-fetch-request', this.onSubmitStart, false)
    window.addEventListener('turbo:submit-end', this.onSubmitEnd, false)
  }

  handleMessage (event) {
    switch (event.data) {
      case 'iframe:closeModal':
        window.modal.hideModal()
        break
    }
  }

  handleLinkClick (event) {
    if (window.location !== window.parent.location) {
      event.preventDefault()

      const linkElement = getClosestElementFor('a')(event.target)
      window.parent.location = linkElement.href
    }
  }

  onSubmitStart (event) {
    if (window.location !== window.parent.location) {
      event.detail.fetchOptions.headers['X-IFRAME-DETECTED'] = 'true'
    }
  }

  onSubmitEnd (event) {
    if (event.detail.success && event.detail.formSubmission.result.fetchResponse.response.status === 205) {
      window.parent.location.reload()
    }
  }
}
