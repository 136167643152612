import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit', 'form']

  connect () {
    this.submissionForm.addEventListener(
      'turbo:submit-start',
      this.submitStart.bind(this)
    )
    this.submissionForm.addEventListener(
      'turbo:submit-end',
      this.submitEnd.bind(this)
    )
  }

  disconnect () {
    this.submissionForm.removeEventListener(
      'turbo:submit-start',
      this.submitStart
    )
    this.submissionForm.removeEventListener('turbo:submit-end', this.submitEnd)
  }

  get submissionForm () {
    return this.hasFormTarget ? this.formTarget : this.element
  }

  submitStart () {
    if (this.submissionForm) {
      this.submitTargets.forEach(submitTarget => {
        submitTarget.disabled = true
      })
    }
  }

  submitEnd () {
    if (this.submissionForm) {
      this.submitTargets.forEach(submitTarget => {
        submitTarget.disabled = false
      })
    }
  }
}
