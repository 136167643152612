import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'
import debounce from 'lodash-es/debounce'

export default class TypeaheadSearchController extends Controller {
  static targets = ['form', 'query', 'results', 'loadingSpinner']
  static values = {
    searchEndpoint: String,
    postEndpoint: String,
    postBody: Object
  }

  initialize () {
    this.conductSearch = debounce(this.conductSearch.bind(this), 500)
    this.previousQuery = ''
    this.abortController = null
  }

  connect () {
    window.addEventListener('clearTypeahead', this.clearResults.bind(this))
    document.addEventListener('click', this.handleClicks.bind(this), false)
    if (this.hasPostEndpointValue) {
      this.queryTarget.addEventListener('keydown', this.handleEnter.bind(this), false)
    }
  }

  disconnect () {
    window.removeEventListener('clearTypeahead', this.clearResults.bind(this))
    document.removeEventListener('click', this.handleClicks.bind(this))

    if (this.hasPostEndpointValue) {
      this.queryTarget.removeEventListener('keydown', this.handleEnter.bind(this))
    }
  }

  get query () {
    return this.queryTarget.value
  }

  conductSearch () {
    this.showLoadingSpinner()
    this.previousQuery = this.query
    this.showSearchResults()
    this.abortController = new AbortController()
    const { signal } = this.abortController
    get(this.searchUrl, { signal }).catch(() => {})
  }

  get searchUrl () {
    const url = new URL(this.searchEndpointValue, window.location.origin)
    url.searchParams.set('query', this.query)
    return url
  }

  fetchResults () {
    if (this.query === '') {
      this.clearResults()
      return
    }

    if (this.query === this.previousQuery) {
      this.showSearchResults()
    } else {
      this.conductSearch()
    }
  }

  handleEnter (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.submitToPostEndpoint()
    }
  }

  abortCurrentRequest () {
    if (this.abortController && !this.abortController.signal.aborted) {
      this.abortController.abort()
    }
  }

  submitToPostEndpoint () {
    post(
      this.postEndpointValue,
      { body: this.postBody, contentType: 'application/json' }
    ).then(response => {
      if (response.ok) {
        this.clearResults()
      }
    })
  }

  get postBody () {
    return Object.assign({}, { value: this.queryTarget.value }, this.postBodyValue)
  }

  handleClicks (event) {
    const clickFromDropdown = [this.queryTarget, this.resultsTarget].includes(
      event.target
    )
    if (!clickFromDropdown) this.hideSearchResults()
  }

  clearResults () {
    this.queryTarget.value = ''
    this.resultsTarget.innerHTML = ''
    this.hideSearchResults()
    this.hideLoadingSpinner()
    this.abortCurrentRequest()
  }

  showSearchResults () {
    this.resultsTarget.classList.remove('hidden')
  }

  hideSearchResults () {
    this.resultsTarget.classList.add('hidden')
  }

  showLoadingSpinner () {
    this.loadingSpinnerTarget.classList.remove('hidden')
  }

  hideLoadingSpinner () {
    this.loadingSpinnerTarget.classList.add('hidden')
  }
}
