import { Application } from '@hotwired/stimulus'

// Custom controllers from the web (e.g. https://www.stimulus-components.com/)
import Carousel from '@stimulus-components/carousel'
import Flatpickr from 'stimulus-flatpickr'
import Reveal from '@stimulus-components/reveal'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

application.register('carousel', Carousel)
application.register('flatpickr', Flatpickr)
application.register('reveal', Reveal)

export { application }
