import { Controller } from '@hotwired/stimulus'

import { get } from '@rails/request.js'

export default class extends Controller {
  static classes = ['hidden']
  static values = {
    conversationWindowId: String,
    historyContainerId: String,
    previousUrl: String
  }

  connect () {
    this.needToFetch = true
    this.removeHeightAfterFetch = false
    this.lasttouchY = null

    setTimeout(() => {
      this.conversationWindow.addEventListener('wheel', this.handleWheelScrollAtTop.bind(this))
      this.conversationWindow.addEventListener('scrollend', this.handleTopOfPage.bind(this))
    }, 1500)
  }

  handleTopOfPage () {
    if (this.conversationWindow.scrollTop <= 120 && this.needToFetch) this.triggerFetch()
  }

  handleWheelScrollAtTop (event) {
    if (event.deltaY < 0 && this.conversationWindow.scrollTop === 0 && this.needToFetch) this.triggerFetch()
  }

  get conversationWindow () {
    return document.getElementById(this.conversationWindowIdValue)
  }

  triggerFetch () {
    this.element.classList.remove(this.hiddenClass)
    this.needToFetch = false
    setTimeout(() => {
      this.scrollAndFetch()
    }, 300)
  }

  async scrollAndFetch () {
    const historyContainer = document.getElementById(this.historyContainerIdValue)

    if (historyContainer.offsetHeight < this.conversationWindow.clientHeight) {
      historyContainer.style = 'height: 100vh;'
      this.removeHeightAfterFetch = true
    }

    const response = await get(this.previousUrlValue)

    if (response.ok && this.removeHeightAfterFetch) {
      setTimeout(() => {
        historyContainer.style = ''
      }, 200)
    }
  }
}
