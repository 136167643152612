import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    path: String
  }

  connect () {
    this.form = document.createElement('form')
    this.form.method = 'POST'
    this.form.action = this.pathValue

    const token = document.createElement('input')
    token.type = 'hidden'
    token.name = document.querySelector('meta[name=csrf-param]').content
    token.value = document.querySelector('meta[name=csrf-token]').content

    this.form.appendChild(token)

    document.body.appendChild(this.form)

    this.element.addEventListener('click', (event) => {
      this.form.submit()
    })
  }
}
